function SelectionDialog(id, parentId, app){
    this.id = id;
    this.parentId = parentId;
    this.app = app;

    this.activeComponent = {};
    this.isVisible = false;

    this.generate();
}

SelectionDialog.prototype.generate = function() {

    //Show multi-select dialog (append before the back-button)
    var parent = d3.select("#" + this.parentId);
    var container = parent.insert("div", ".back-button")
        .attr("class", "multi-select-container")
        .attr("id", this.id);

    //remove clear div
    d3.select("main > .clear-div").style("display", "none");

    container.append("div").attr("class", "multi-select-item")
        .attr("id", "multi-select-desc")
        .html("<span class=\"bold\">Multi-Select</span> - Tap or drag to select items");

    this.close = container.append("div").attr("class", "multi-select-item")
        .attr("id", "multi-select-close")
        .text("×");

    this.clear = container.append("div").attr("class", "multi-select-item")
        .attr("id", "multi-select-clear")
        .text("Clear");

    this.selectAll = container.append("div").attr("class", "multi-select-item")
        .attr("id", "multi-select-all")
        .text("Select All");
};

SelectionDialog.prototype.setActiveComponent = function(component) {
    if(!this.isVisible) {
        return;
    }

    //Un-highlight previous component.
    if(!$.isEmptyObject(this.activeComponent)) {
        this.clearHighlight();
    }

    this.activeComponent = component;
    //console.log('active component =  ' + this.activeComponent.identifier);
    var that = this;

    this.selectAll.on("click", function(){
        that.activeComponent.handleSelectAll();
    });

    this.clear.on("click", function(){
        that.activeComponent.clearAllSelections();
    });

    this.close.on("click", function(){
        that.app.endMultiSelectMode();
        that.hide();
    });

    this.highlightComponent();
};

SelectionDialog.prototype.show = function() {
    var expander = d3.select("#" + this.id);
    expander.style("width", "560px");
    //d3.select('main > .clear-div').style('display', 'none');

    this.isVisible = true;
};

SelectionDialog.prototype.hide = function() {
    var expander = d3.select("#" + this.id);
    expander.style("width", "0px");

    //commenting out to prevent 'jump' when closing dialog.
    //d3.select('main > .clear-div').style('display', 'block');

    this.clearHighlight();
    this.isVisible = false;
};

SelectionDialog.prototype.highlightComponent = function() {
    var component = this.activeComponent;
    var containerId = component.getContainerId();
    d3.select("#" + containerId).classed("multi-select-highlight", true);
};

SelectionDialog.prototype.clearHighlight = function() {
    var component = this.activeComponent;
    var containerId = component.getContainerId();
    d3.select("#" + containerId).classed("multi-select-highlight", false);
};

SelectionDialog.prototype.destroy = function () {
    this.isVisible = false;
    //Remove from DOM
    d3.select("main > .clear-div").style("display", "block");
    d3.select("#" + this.id).remove();
};
