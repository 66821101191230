// ==============================================================
// ==============================================================
// DRAG TABS STUFF
// ==============================================================
// ==============================================================

/*

NAMING CONVENTIONS:

	- <input> "name" attribute is the root name for the tabs
	- the tab container "id" must have the same name root name with the suffix "-container"
	- tab <label>'s must have the "name" attribute with the same root name but with the suffix "-label"

*/

var gDragTabLabel = null; // label of tag currently being dragged
var gDragTabLabelWidth = 0; // width of tag currently being dragged
var gCurrentInsertTabLabel = null;	// tab label to insert before
var gDropContainer = null;	// tab container that we are dropping on

/*
 * set the tab to insert before, if tab is null then insert at end
 */
function setInsertTabLabel(tabLabel, clearAll) {
    if (tabLabel == gCurrentInsertTabLabel) {
        return;
    } else {
        // remove the margin for the old tab
        if (gCurrentInsertTabLabel) {
            gCurrentInsertTabLabel.style.marginLeft = "-1px";
        }

        if (clearAll) {
            gCurrentInsertTabLabel = null;
            return;
        }

        // set the new tab
        gCurrentInsertTabLabel = tabLabel;

        // set the new margin
        if (gCurrentInsertTabLabel) {
            gCurrentInsertTabLabel.style.marginLeft = gDragTabLabelWidth + "px";
        }
    }
}


function handleTabDragStart(ev) {
    gDragTabLabel = ev.target;
    if (ev.dataTransfer && ev.dataTransfer.setData) {
        ev.dataTransfer.setData('text', 'Data Transfer');
    }

    if (!gDragTabLabel.style) {
        gDragTabLabel = gDragTabLabel.parentNode;
    }


    gDragTabLabelWidth = gDragTabLabel.offsetWidth;

    gDragTabLabel.style.opacity = "0.6";

    // console.log("tab width=" + gDragTabLabelWidth);
}


function handleTabDragEnd(ev) {
    ev.preventDefault();

    gDragTabLabel.style.opacity = "1";

    // clean up
    setInsertTabLabel(null);

    gDragTabLabel = null;
    gCurrentInsertTabLabel = null;
    gDropContainer = null;
}



function handleTabDragOver(ev) {
    if (gDragTabLabel == null) {
        return false;
    }
    // which tab container is this being drug over?
    gDropContainer = ev.currentTarget;

    // get the x location of the event
    var position = getPageXY(ev);
    var xpos = position.x;
    var ypos = position.y;
    var tabLabels = document.getElementsByName(gDropContainer.tabGroupName + "-label");

    if (tabLabels.length == 0) {
        setInsertTabLabel(null, true);
        return false;
    }
    var preCheckY = getAbsolutePosition(tabLabels[0]).y;

    if (preCheckY + 30 < ypos) {
        setInsertTabLabel(null, true);
        return false;
    }
    ev.preventDefault();

    for (var i = 0; i < tabLabels.length; i++) {
        var r = getAbsolutePosition(tabLabels[i]);

        if (xpos < r.right) {
            setInsertTabLabel(tabLabels[i]);
            return;
        }
    }

    // NOTE: if we don't find a tab to insert before then insert it at the end
    setInsertTabLabel(null);

}

function handleTabDrop(ev) {
    if (!gDragTabLabel) {
        return;
    }
    ev.preventDefault();

    console.log("gDropContainer=" + gDropContainer);

    // move to the tab to the new location
    var dragTab = gDragTabLabel.parentNode;
    var insertTab = gCurrentInsertTabLabel ? gCurrentInsertTabLabel.parentNode : null;
    // move the dragTab befor the insertTab
    gDropContainer.insertBefore(dragTab, insertTab);

    // the tab container may have changed so we need to make sure that the <input type="radio"> and the <label> are
    // updated with the proper "name" attributes
    var inputElements = gDragTabLabel.parentNode.getElementsByTagName("input");  // get the list of input elements
    var dragTabInput = inputElements[0]; // there should only be one


    d3.select(dragTabInput).attr('name', gDropContainer.tabGroupName);
    d3.select(gDragTabLabel).attr('name', gDropContainer.tabGroupName + "-label");
}


//
// Makes a tab container have draggable tabs
//
function makeTabsDraggable(tabGroupName, clickCallback) {
    var tabContainerId = tabGroupName + "-container";
    var tabLabelName = tabGroupName + "-label";

    var tabContainer = document.getElementById(tabContainerId);
    tabContainer.addEventListener('dragover', handleTabDragOver, false);
    tabContainer.addEventListener('drop', handleTabDrop, false);

    // store the tab group name in the tab container
    tabContainer.tabGroupName = tabGroupName;

    // add handlers to all the tab labels
    var tabGroupA = document.getElementsByName(tabLabelName);

    var tabLabel;
    for (var i = 0; i < tabGroupA.length; i++) {
        tabLabel = tabGroupA[i];
        var tabDragEnd = function(event) {
            handleTabDragEnd(event);
            clickCallback(event);
        };

        tabLabel.addEventListener('dragstart', handleTabDragStart, false);
        tabLabel.addEventListener('dragend', tabDragEnd, false);
        tabLabel.addEventListener('click', clickCallback, false);
    }
}