/* ----------------------------------------------------------- */
/* SEARCH PULL DOWN - shows and hides the search pull down */
/* ----------------------------------------------------------- */


//
// CONSTRUCTOR: handles hiding/showing of the search pull down
//
function SearchPulldown(searchBoxId, searchPulldownId) {
    let searchShieldId = "local-search-shield";
    searchPulldownId = searchPulldownId || "local-search-pulldown";

    let previousEntry = SearchPulldown.current;
    if (previousEntry) {
        previousEntry.stop();
    }

    this.searchShown = false;
    this.searchShield = document.getElementById(searchShieldId);
    this.searchBox = document.getElementById(searchBoxId);
    this.searchPulldown = document.getElementById(searchPulldownId);

    // save a reference to the bound click method so we can stop it if needed
    this.clickShieldMethod = this.onClickShield.bind(this);

    // call if the user clicks on the shield
    this.searchShield.addEventListener("click", this.clickShieldMethod);

    SearchPulldown.current = this;
}

SearchPulldown.current = undefined;

//
// Shows the search pulldown in the proper location
//
SearchPulldown.prototype.show = function(ev) {
    if (!this.searchShown) {
        // show the search pull down
        this.searchPulldown.className = "local-search-pulldown-active";

        // shield up!
        this.searchShield.classList.remove( "shield-hidden" );
        this.searchShield.classList.add( "shield-active" );

        // report state as top css class
        document.body.classList.add("showing-modal");

        // make sure the menu is set to absolute position and set at the 0,0 pos
        this.searchPulldown.style.position = "absolute";
        this.searchPulldown.style.top = "0px";
        this.searchPulldown.style.left = "0px";

        var searchBoxPos = getAbsolutePosition(this.searchBox); // get the location of the icon
        var searchPulldownPos = getAbsolutePosition(this.searchPulldown);   // get the location of menu

        // compute the offset of the menu relative to the icon
        var offset = {top: (searchBoxPos.top - searchPulldownPos.top), left: (searchBoxPos.left - searchPulldownPos.left)};

        //console.log("searchBoxPos = " + searchBoxPos.top);
        //console.log("searchPulldownPos = " + searchPulldownPos.top);
        //console.log(offset);

        // top is always the same
        this.searchPulldown.style.top = (offset.top + 30) + "px";

        // if the menu is to the far right then put then left justify the pull down
        if ((searchBoxPos.left + 640) > document.documentElement.clientWidth) {
            var leftOffset = searchBoxPos.right - 640;
            this.searchPulldown.style.left = leftOffset + "px";
            this.searchPulldown.style.right = "";
        } else {
            this.searchPulldown.style.left = (offset.left) + "px";
            this.searchPulldown.style.right = "";
        }

        // don't show the regular context menu, we are showing our own menu
        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }

        this.searchShown = true;
    }
};

//
// hides search pull down
//
SearchPulldown.prototype.hide = function () {
    if (this.searchShown) {
        // hide the shield
        this.searchShield.classList.remove( "shield-active" );
        this.searchShield.classList.add( "shield-hidden" );

        // clear state css class
        document.body.classList.remove("showing-modal");

        // hide the pull down
        this.searchPulldown.className = "local-search-pulldown";

        // set the location back to the top left of the page
        this.searchPulldown.style.top = "0px";
        this.searchPulldown.style.left = "0px";

        this.searchShown = false;
    }
};


//
// This Method called when shield is clicked
//
SearchPulldown.prototype.onClickShield = function() {
    this.hide();
};


//
// call this if you want to stop using this class
//
SearchPulldown.prototype.stop = function() {
    this.searchShield.removeEventListener("click", this.clickShieldMethod);
};



