/* ============================================================= */
/* TIME PICKER                                                   */
/* ============================================================= */


var kShowTicks = true;
var kDiameter = 200;
var kNumRadius = 28 / 2;  // location of hour numbers

// offset of dial relative to field
var kYDialOffset = 48;
var kXDialOffset = -47;

//------------------------------- GLOBAL VARIABLES -------------------------------

var gTimePicker = null;     // pop up time picker
var gTimeField = null;      // time input field
var gClockHand;             // clock hand
var gDial;                  // big transparent dial

var gDuration = false;     // are we picking a time or a duration?

var gTimePickerShown = false;   // is the picker shown?

// time/duration object
var gTime = {duration: false, hour: 8, minute: 0, am: true, valid: true, unlimited: false};    

var gSettingTime = false;   // are we dragging the dial pointer
var gPrevDeg = -1;

/* =================================================================== */
/* utility functions */




function setMiddle(topSectionOn) {
    console.log("setMiddle " + topSectionOn);
    if (topSectionOn) {
        document.getElementById("tp-mid-top").classList.add("tp-selected");
        document.getElementById("tp-mid-bot").classList.remove("tp-selected");
    } else {
        document.getElementById("tp-mid-bot").classList.add("tp-selected");
        document.getElementById("tp-mid-top").classList.remove("tp-selected");
    }
}


function updatePicker() {
    // is it a duration?
    if (gDuration) {
        // is here no limit
        if (gTime.unlimited) {
            gClockHand.style.visibility = "hidden";
            setMiddle(false);
        } else {
            gClockHand.style.visibility = "visible";
            setDial(gTime);
            setMiddle(true);
        }
    } else {
        gClockHand.style.visibility = "visible";
        setDial(gTime);
        setMiddle(gTime.am);
    }
}


function checkTurnOffUnlimited() {
    // if the user clicks on the hour and it's currently set to unlimited then turn off unlimited
    if (gTime.duration && gTime.unlimited) {
        gTime.unlimited = false;
        gClockHand.style.visibility = "visible";
        updatePicker();
    }
}


function moveDial(ev) {
    console.log("moveDial");
    
    checkTurnOffUnlimited();
    
    var pos = getPageXY(ev);
    var dialPos = getAbsolutePosition(gTimePicker);
    
    var centerPos = {x: (dialPos.left + 100), y: (dialPos.top + 100)};
    var diff = {x: (pos.x - centerPos.x), y: (pos.y - centerPos.y)};
    
    var slope =  diff.y / diff.x;
    
    console.log("diff: x=" + diff.x + " y=" + diff.y);

    var radians = Math.atan(slope);    
    var deg = radians * (180 / Math.PI) - 90;
    
    if (diff.x < 0) {
        deg = deg - 180;
    }
    
    /* round to 15 min */
    deg = Math.round(Math.round(deg / 7.5) * 7.5);
    
    // only update if there is a change
    if (deg != gPrevDeg) {
        gPrevDeg = deg;
            
        // make sure it's modulo arithmetic
        var pdeg = (deg + 360 + 180) % 360;
    
        console.log("slope=" + slope + " deg=" + deg + " pdeg=" + pdeg);

        gTime.valid = true;
        
        gTime.hour = Math.floor(pdeg / 30);
        if (gTime.hour == 0 && !gTime.duration) {
            gTime.hour = 12;
        }
        
        gTime.minute = (Math.floor(pdeg / 7.5) % 4) * 15;
        gTime.second = 0;
    
        setDial(gTime);
        
        updateTimeField(); 
        
        gTimeField.select();   
    }
    
    
}


function parseTimeField() {
    // parse the time/duration
    if (gDuration) {
        gTime = parseDuration(gTimeField.value);
    } else {
        gTime = parseTime(gTimeField.value);
    }
    
    console.log("parseTimeField gTime=" + gTime);
}







/* ============================================================= */
/* TIME PICKER                                                   */
/* ============================================================= */


var kShowTicks = true;
var kDiameter = 200;
var kNumRadius = 28 / 2;  // location of hour numbers

// offset of dial relative to field
var kYDialOffset = 48;
var kXDialOffset = -47;

// when picking a hour for duration there are 12 hours on the clock so it 360/12 = 30 degrees per hour
var kDegreesPerHour = 30;

// when picking a day for duration there are 12 days so it 360/12 = 30 degrees per day
var kDegreesPerDay = 30;

// number of segments in dial 12 hour or 12 days
var kSegmentsInDial = 12;
                
var kMsInDay = 24 * 60 * 60 * 1000;
var kMsInHour = 60 * 60 * 1000;
var kMsInMinute = 60 * 1000;
var kMsInSecond = 1000;


//------------------------------- GLOBAL VARIABLES -------------------------------

var gTimePicker = null;         // pop up time picker
var gTimeField = null;          // time input field
var gClockHand;                 // clock hand
var gDial;                      // big transparent dial

var gDuration = false;          // are we picking a time or a duration?
var gDayDuration = false;       // are we picking hours our days for duration?

var gTimePickerShown = false;   // is the picker shown?

// time/duration object
var gTime = {duration: false, day: 0, hour: 8, minute: 0, am: true, valid: true};    

var gSettingTime = false;       // are we dragging the dial pointer
var gPrevDeg = -1;              // previous value of pointer degree


/* =================================================================== */
/* utility functions */


/*
 * get all the variants of rotate for browsers
 */
function rotateStyle(deg) {
    return  '-webkit-transform:rotate(' + deg + 'deg);' +
               '-moz-transform:rotate(' + deg + 'deg);' +
                '-ms-transform:rotate(' + deg + 'deg);' +
                 '-o-transform:rotate(' + deg + 'deg);' +
                    'transform:rotate(' + deg + 'deg);';
}


/*
 * lenient parsing of duration in the "1d 2h 30m" format
 */
function parseDuration(st) {
    //console.log('parseDuration st="' + st + '"');

    var kLegalDurationChars = "dhms:, ";
    
    // trim and convert to lower case
    var lst = st.trim().toLowerCase();

    var duration = {duration: true, valid: false, day: 0, hour: 0, minute: 0, second: 0, ms: 0};
    
    // if empty string just return 0 for everything
    if (lst.length == 0) {
        duration.valid = true;
        return duration;
    }
        
    var i = 0;
    
    // skip leading non-numbers
    while (i < st.length && !(st[i] >= '0' && st[i] <= '9')) {
        // are there invalid characters?
        if (kLegalDurationChars.indexOf(st[i]) < 0) return duration;
        i++;
    }
    
    var numberPairs = new Array();
    
    // split each number/unit pair into an array
    while (i < st.length) {
        // while it's a integer number do
        var numStr = "";
        while (i < st.length && (st[i] >= '0' && st[i] <= '9')) {
            numStr += st[i++];
        }
    
        // parse the unit (or white space)
        var unitStr = "";
        while (i < st.length && !(st[i] >= '0' && st[i] <= '9')) {
            // are there invalid characters?
            if (kLegalDurationChars.indexOf(st[i]) < 0) return duration;
            unitStr += st[i++];
        }
        
        // if there is a number then push on to the list
        if (numStr.length > 0) {
            numberPairs.push({number: parseInt(numStr), unit: unitStr}); 
        }
    }
        
    // if the string does not contain a "d", "h", "m" or "s" then allow just the number for a shortcut
    if (lst.indexOf("d") < 0 && lst.indexOf("h") < 0 && lst.indexOf("m") < 0 && lst.indexOf("s") < 0) {
        
        // there are just numbers and no units
        if (numberPairs.length == 3) {
            // there 3 numbers, assume day, hour, minute format
            
            // days
            if (numberPairs[0].number >= 12) return duration;
            duration.day = numberPairs[0].number;

            // hours
            if (numberPairs[1].number >= 24) return duration;
            duration.hour = numberPairs[1].number;

            // minutes
            if (numberPairs[2].number >= 60) return duration;
            duration.minute = numberPairs[2].number;

        } else if (numberPairs.length == 2) {
            // there 2 numbers, assume hour, minute format
            
            // hours
            if (numberPairs[0].number >= 24) return duration;
            duration.hour = numberPairs[0].number;
            
            // minutes
            if (numberPairs[1].number >= 60) return duration;
            duration.minute = numberPairs[1].number;
            
        } else if (numberPairs.length == 1) {
            // there 1 numbers, assume just minutes
            
            // minutes
            if (numberPairs[0].number >= 60) return duration;
            duration.minute = numberPairs[0].number;
        } else {
            // return invalid format
            return duration;
        }
    } else {
        // for each number/unit pair do
        for (var j = 0; j < numberPairs.length; j++) {

            // is this a day?
            if (numberPairs[j].unit.indexOf("d") >= 0) {
                duration.day = numberPairs[j].number;
            }
            
            // is this a hour?
            if (numberPairs[j].unit.indexOf("h") >= 0) {
                duration.hour = numberPairs[j].number;
            }
            
            // is this a minute?
            if (numberPairs[j].unit.indexOf("m") >= 0) {
                duration.minute = numberPairs[j].number;
            }

            // is this a second?
            if (numberPairs[j].unit.indexOf("s") >= 0) {
                duration.second = numberPairs[j].number;
            }
        }
    }
    
    // passed all the checks, it's valid!
    duration.valid = true;

    // calculate duration in ms
    duration.ms += (duration.day * kMsInDay) + (duration.hour * kMsInHour) + (duration.minute * kMsInMinute) + (duration.second * 1000);
    
    //console.log("Parsed Duration=" + formatTime(duration));

    return duration;
}


/*
 * lenient parsing of time in "h:mm AM/PM" 
 */
function parseTime(st) {
    var pvalid = true;
    var pam = false;
    var pmin = 0;
    var phour = 0;
    var pms = 0;
        
    // make sure it starts with a number
    var number = parseInt(st);
    if (isNaN(number) || number < 1 || st.length == 0) {
        pvalid = false;
    } else {
    
        var split = st.split(":");
    
        var phour = parseInt(split[0]);
        if (isNaN(phour) || phour < 1 || phour > 12) {
            phour = 0;
            pvalid = false;
        }
    
        if (split.length > 1) {
            if (split[1].length > 0) {
                pmin = parseInt(split[1]);
                if (isNaN(pmin) || pmin < 0 || pmin > 59) {
                    pmin = 0;
                    pvalid = false;
                }
            }
        }
    
        // if it has "a" then assume it's AM
        pam = st.indexOf("a") >= 0 || st.indexOf("A") >= 0;
        
        var fullDayHour = phour;
        
        // convert to 24h time
        if (pam && phour == 12) {
            fullDayHour = 0;
        } else if (!pam) {
            fullDayHour += 12;
        }
        
        // time in ms
        pms = (fullDayHour * kMsInHour) + (pmin * kMsInMinute);
    }
        
    return {duration: false, valid: pvalid, hour: phour, minute: pmin, am: pam, ms: pms};
}


/*
    format time to "h:mm AM/PM" format or "Xd Xh Xm" format given a object with the following attributes:
 
    duration        // true/false if this is a durration
    
    if it's a time:
        hour        // 1-12
        minute      // 0-59
        am          // true if AM, false if PM
        valid       // is it a valid time?
   
    if it's a durration: 
        day         // 0..n
        hour        // 1-12
        minute      // 0-59
        valid       // is it a valid duration?

 */
function formatTime(t) {
    var timeStr = "";
    
    if (t.duration) {
        if (t.day > 0) {
            timeStr = t.day + "d";
        }
    
        if (t.hour > 0) {
            // do we need a separator space?
            if (timeStr.length > 0) {
                timeStr += " ";
            }

            timeStr += t.hour + "h";
        }
    
        if (t.minute > 0) {
            // do we need a separator space?
            if (timeStr.length > 0) {
                timeStr += " ";
            }
            
            timeStr += t.minute + "m";
        }

        if (t.second && t.second > 0) {
            // do we need a separator space?
            if (timeStr.length > 0) {
                timeStr += " ";
            }

            timeStr += t.second + "s";
        }
        
        // no time should be set to 0m
        if (timeStr.length == 0) {
            timeStr = "0m";
        }

    } else {
        if (t.valid) {
            timeStr = t.hour + ":" + padZeros(t.minute, 2) + " " + (t.am ? "AM" : "PM");
        }
    }
    
    return timeStr;
}


/*
 * format time from given the ms since midnight Jan 1, 1970
 * 
 * Note: this only uses the hours and minutes and ignores the year/month/day
 *
 * params:
 *      ms - time in ms since midnight Jan 1, 1970
 *      duration - set to true if it's a duration
 *
 * returns:
 *      string in "h:mm AM/PM" format or "Xd Xh Xm" format
 *
 */
function formatTimeMS(ms, duration, includeSeconds) {
    var date = new Date(ms);
    
    if (duration) {
        var d, h, m, remainderMs, s;
        
        remainder = ms;
        
        d = Math.floor(remainder / kMsInDay);
        remainder = remainder - (d * kMsInDay);
        
        h = Math.floor(remainder / kMsInHour);
        remainder = remainder - (h * kMsInHour);

        m = Math.floor(remainder / kMsInMinute);
        remainder = remainder - (m * kMsInMinute);

        if (includeSeconds) {
            s = Math.floor(remainder / kMsInSecond);
            remainder = remainder - (s * kMsInSecond);
        }

        return formatTime({duration: true, valid: true, day: d, hour: h, minute: m, second:s});
        
    } else {
        var hr = date.getHours();   // 0-23
        
        // convert to 12hr time
        if (hr == 0) {
            hr = 12;
        } else if (hr >= 12) {
            hr = hr - 12 + 1;
        }
        
        return formatTime({duration: false, valid: true, hour: hr, minute: date.getMinutes(), am: date.getHours() < 12});
    }
    
}




/* update functions */

function updateTimeField() {
    console.log(">>>>>updateTimeField " + formatTime(gTime));
    if (gTime.valid) {
        gTimeField.value = formatTime(gTime);
    }
}


function setDial(time) {
    // compute how many degrees to rotate
    var deg = 0;
    
    if (gDuration && gDayDuration) {
        // showing duration in days, not hours, 12 segments in the dial do 30 degs per day
        deg = (time.day + (time.hour / 24) + (time.minute / 1440)) * kDegreesPerDay;
        
        console.log("setDial days=" + time.day + " minute=" + time.minute + " deg=" + deg);

    } else {
        deg = (time.hour * kDegreesPerHour) + ((time.minute / 60) * kDegreesPerHour);
        
        console.log("setDial hour=" + time.hour + " minute=" + time.minute + " deg=" + deg);
    }
    
    // it's rotated 180 degrees
    var hdeg = (deg + 360 + 180) % 360;
    
    // rotate the needed using the proper property for each browser
    gClockHand.style[gTransformProp] = 'rotate(' + hdeg + 'deg)';
}


/* =============================================== */
/* event handlers dial  */


function onDownDial(ev) {
    gSettingTime = true;
    moveDial(ev);
    
    ev.preventDefault();
    ev.stopPropagation();
}


function onMoveDial(ev) {
    if (gSettingTime) {
        moveDial(ev);
    }
    
    ev.preventDefault();
    ev.stopPropagation();
}


function onUpDial(ev) {
    if (gSettingTime) {
        moveDial(ev);
        gSettingTime = false;
    }

    ev.preventDefault();
    ev.stopPropagation();
}


function onMiddle(ev, topClicked) {
    console.log("topClicked " + topClicked);
    if (gTime.duration) {
        gDayDuration = !topClicked;
        
        // reset the duration
        if (gDayDuration) {
            gTime.day = 1;
            gTime.hour = 0;
        } else {
            gTime.day = 0;
            gTime.hour = 1;
        }
            
        gTime.minute = 0;
        gTime.second = 0;
        
        updatePicker();
    } else {
        gTime.am = topClicked;
        setMiddle(topClicked);
    }
    
    updateTimeField();

    gTimeField.select();   

    ev.preventDefault();
    ev.stopPropagation();
}



function showTimePicker(ev, timeFieldId, duration) {
    console.log("showTimePicker");
    
    // do we need to make a timepicker div? We just keep it around for next time.
    if (gTimePicker == null) {
         // Create a <div> for the calendar              
        gTimePicker = document.createElement("div");  

        gTimePicker.className = "time-picker";
        
        document.body.appendChild(gTimePicker); 
    }
    
    gTimeField = document.getElementById(timeFieldId);
    gDuration = duration;
    
    // store if this is a duration in the field
    gTimeField.tpDuration = duration;

    var html = "";

    // biggest dial
    html += '<div class="dial"></div>';

    // red pointer
    html += '<div class="pointer"></div>';
    
    var x, y, p, h, radius;
            
    // hour #'s 
    p = Math.PI - (Math.PI / 6);
    radius = 76;
    var kCenterX = kDiameter / 2 - kNumRadius;
    var kCenterY = kDiameter / 2 - kNumRadius;
    for (var i = 1; i <= 12; i++) {
        x = kCenterX + Math.sin(p) * radius;
        y = kCenterY + Math.cos(p) * radius;
        
        var largeNum = false && (i % 3) == 0 ? "large-number" : "";
        
        // if this is a duration then make to top number 0
        h = (duration && i == 12) ? 0 : i;
        
        html += '<div id="tp-hr' + i + '" class="number ' + largeNum + '" ' +
                  ' style="top: ' + y + 'px; left: ' + x + 'px;">' + h + 
                '</div>';
        
        p -= (Math.PI / 6);
    }
    
    // ticks
    if (kShowTicks) {
        p = Math.PI - (Math.PI / 6);
        radius = 94;
        var kTickCenterX = kDiameter / 2 - 0;
        var kTickCenterY = kDiameter / 2 - 3;
        var deg = 30;
        for (var i = 1; i <= 48; i++) {
            var smalltick = (i % 4 == 1);
            var longtick = (i % 4 == 3);
            
            x = Math.floor(kTickCenterX + Math.sin(p) * (radius + (smalltick ? 0 : 2))) + (smalltick ? 0 : 2);
            y = Math.floor(kTickCenterY + Math.cos(p) * (radius + (smalltick ? 0 : 2))) + (smalltick ? 0 : 2);
            
            var tickClassName = smalltick ? "" : "small";
            if (longtick) {
                tickClassName = "long";
            }
              
            html += '<div class="tick ' + tickClassName + '" ' +
                    ' style="top: ' +  y + 'px; left: ' + x + 'px; ' + rotateStyle(Math.round(deg)) + '"></div>';
        
            p -= (Math.PI / (6 * 4));
            deg += 7.5;
        }
    }
    
    // minute dial
    html += '<div class="minute-dial"></div>';
    
    // hand and needle
    html += '<div id="tp-clock-hand"><div id="tp-needle"></div></div>';

    // click dial
    html += '<div id="click-dial"></div>';

    // am/pm or hrs/days
    html += '<div id="tp-mid-top" class="mid-top" onmousedown="onMiddle(event, true)">' + (duration ? "hours" : "AM") + '</div>';
    html += '<div id="tp-mid-bot" class="mid-bot" onmousedown="onMiddle(event, false)">' + (duration ? "days" : "PM") + '</div>';
    
    // set all the HTML into the main div
    gTimePicker.innerHTML = html;
    
    var fieldPos = getAbsolutePosition(gTimeField);
    var menuPos = getAbsolutePosition(gTimePicker);
    
    // compute the offset
    var offset = {top: (fieldPos.top - menuPos.top), left: (fieldPos.left - menuPos.left)};
    
    gClockHand = document.getElementById("tp-clock-hand");
    
    gDial = document.getElementById("click-dial");
    
    gDial.addEventListener('mousedown', onDownDial);
    gDial.addEventListener('mousemove', onMoveDial);
    gDial.addEventListener('mouseup', onUpDial);
    gDial.addEventListener('mouseleave', onUpDial);
                     
    // do we need to add Listeners for touch devices?
    if (typeof window.ontouchstart !== 'undefined') {
        gDial.addEventListener('touchstart', onDownDial);
        gDial.addEventListener('touchmove', onMoveDial);
        gDial.addEventListener('touchend', onUpDial);
    }
    
    gTimePicker.style.top = (offset.top + kYDialOffset) + "px";
    gTimePicker.style.left = (offset.left + kXDialOffset) + "px";
    
    parseTimeField();

    // update the picker to match gTime
    updatePicker();
    
    // show the picker
    gTimePicker.classList.add("tp-shown");

    gTimePickerShown = true;
    
}


function hideTimePicker(ev) {
    if (gTimePicker) {
        // hide the hand first so it does not flash
        gClockHand.style.visibility = "hidden";
        
        gTimePicker.classList.remove("tp-shown");

        // reset the position back to to 0,0 so it's out of the way
        gTimePicker.style.top = "0px";
        gTimePicker.style.left = "0px";

        gTimePickerShown = false;
    }
}


/* =============================================== */
/* event handlers for text input events  */

function onTimeFocus(ev, id, duration) {
    console.log(">>> onTimeFocus");
    
    // if id is specified then use it
    if (id) {
        showTimePicker(ev, id, duration);
    } else {
        // get the id of the current event target
        showTimePicker(ev, ev.currentTarget.id, ev.currentTarget.tpDuration);
    }
    
    console.log("<<< onTimeFocus");
}


function onTimeBlur(ev) {
    console.log(">>> onTimeBlur");

    parseTimeField();
    updateTimeField();
    hideTimePicker(ev);

    console.log("<<< onTimeBlur");
}


function onTimeInput(ev) {
    console.log(">>> onTimeInput");

    parseTimeField();
    updatePicker();

    console.log("<<< onTimeInput");
}


/* ========================================================== */
/* init function */


/*
 *  initTimeInput(fieldId, ms, duration)
 *
 *  inits a input field to be a time picker
 *
 *  params:
 *      fieldId - id of the input field
 *      ms - time to init the field to in ms
 *      duration - true/false if it's a duration
 */
function initTimeInput(fieldId, ms, duration) {
    console.log(">>> initTimeInput");

    var field = document.getElementById(fieldId);
    
    field.value = formatTimeMS(ms, duration);
    
    field.tpDuration = duration;
    
    field.placeholder = duration ? "#d #h #m" : "h:mm am/pm";
    
    field.addEventListener('focus', onTimeFocus);
    field.addEventListener('blur', onTimeBlur);
    field.addEventListener('input', onTimeInput);
    
    console.log("<<< initTimeInput");
}



/* 
 * get the time from a time input field
 *
 * returns time object
 */
function getTimeField(field) {
    return field.tpDuration ? parseDuration(field.value) : parseTime(field.value);
}    



/*
 *  getTimeFieldId(fieldId)
 *
 *  gets the value of a time field
 *  
 *  returns a object with the following attributes:
 *
 *  duration        // true/false if this is a durration
 *  
 *  if it's a time:
 *      valid       // is it a valid time?
 *      hour        // 1-12
 *      minute      // 0-59
 *      am          // true if AM, false if PM
 *      ms          // time in milliseconds  
 *
 *  if it's a duration: 
 *      valid       // is it a valid duration?
 *      day         // 0..n
 *      hour        // 0-23
 *      minute      // 0-59
 *      ms          // duration in milliseconds  
 *
 */
function getTimeFieldId(fieldId) {
    return getTimeField(document.getElementById(fieldId));
}



/* 
 *  used for form validation
 *
 *  returns true if field is a valid time or duration
 */
function validTime(field) {
    var time = getTimeField(field);
    return time.valid;
}

/*
 * add 0's to front of integer
 */
function padZeros(num, places) {
    var st = "" + num;
    return "00000000".substring(0, places - st.length) + st;
}

/*
 * formats the date in the M/D/YY (or M/D/YYYY if less than 2000) format
 */
function formatDate(date) {
    // only show 2 digits if the date is in the 21st century, '9/15/15' is 1915-9-15 on Firefox so we cant trim off the value of year
    var year = date.getFullYear();//date.getFullYear() >= 2000 ?  date.getFullYear() - 2000 : date.getFullYear();

    return (date.getMonth() + 1) + "/" + date.getDate() + "/" + padZeros(year, 2);
}

/*
 * lenient parsing of dates in MM/DD/YY format
 */
function parseDate(st) {

    var split = st.split("/");

    // must have at lest two parts to be a date
    if (split.length < 2) {
        return null;
    }

    var month = parseInt(split[0]);
    if (isNaN(month) || month < 1 || month > 12) {
        return null;
    }

    var day = parseInt(split[1]);
    if (isNaN(day) || day < 1 || day > 31) {
        return null;
    }

    // if no year is include then assume this year
    var year;
    if (split.length > 2) {
        year = parseInt(split[2]);
        if (isNaN(year)) {
            year = new Date().getFullYear();
        } else {

            if (year > 9999) {
                return null;
            }

            // assume that it's in the 2000's if just two digits
            if (year >= 0 && year <= 99) {
                year += 2000;
            }
        }
    } else {
        year = new Date().getFullYear();
    }

    var date = new Date(year, month - 1, day);

    console.log("<<<< parsedate=" + date);

    return date;
}

